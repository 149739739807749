@import "../../theme/variables.scss";

#createScene .buttonDelete {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-top: getSpace("xl");
  border-radius: getSpace("xxs");
  border: 1px solid getColor("utility", "separator") !important;
  padding: 13px 24px 13px 24px !important;
  height: getSpace("xl");
}

#createScene .buttonDeleteColumn {
  text-align: center !important;
}

#createScene .createEditSceneSpinner {
  margin-left: 50%;
  height: 50vh;
}
