/** Color Variables **/
$colorMap: (
  primary: (
    base: #00d2a6,
    base-rgb: rgb(0, 210, 166),
    dark: rgba(0, 210, 166, 0.64),
  ),
  whiteColor: (
    base: #f5f5f5,
    a96: rgba(255, 255, 255, 0.96),
    a64: rgba(255, 255, 255, 0.64),
    a32: rgba(255, 255, 255, 0.32),
  ),
  darkGreyColor: (
    base: #9f9f9f,
  ),
  darkColor: (
    a96: rgba(0, 0, 0, 0.96),
    a64: rgba(0, 0, 0, 0.64),
    a36: rgba(0, 0, 0, 0.36),
  ),
  orangeColor: (
    base: #FFA500,
  ),
  elevation: (
    a00: #0e1010,
    a01: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.07),
        rgba(255, 255, 255, 0.07)
      ),
      #0e1010,
    ),
    a02: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.11),
        rgba(255, 255, 255, 0.11)
      ),
      #0e1010,
    ),
    a03: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.15),
        rgba(255, 255, 255, 0.15)
      ),
      #0e1010,
    ),
    a04: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.19),
        rgba(255, 255, 255, 0.19)
      ),
      #0e1010,
    ),
    a05: (
      linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.24),
        rgba(255, 255, 255, 0.24)
      ),
      #0e1010,
    ),
  ),
  gradient: (
    light:
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.06) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
    dark:
      linear-gradient(179.65deg, #262828 0.28%, #1c1e1e 84.4%, #0e1010 99.67%),
  ),
  warning: (
    base: #ffb950,
  ),
  critical: (
    base: #f83a61,
  ),
  information: (
    base: #2e8ce4,
  ),
  glass: (
    thick: rgba(14, 16, 16, 0.8),
    medium: rgba(28, 30, 30, 0.64),
    thin: rgba(14, 16, 16, 0.24),
    primarythin: rgba(0, 210, 166, 0.08),
    criticalthin: rgba(248, 58, 97, 0.08),
    whitethin: rgba(255, 255, 255, 0.08),
  ),
  utility: (
    border: rgba(14, 16, 16, 0.16),
    outline: rgba(255, 255, 255, 0.08),
    separator: rgba(255, 255, 255, 0.12),
    disconnected: rgba(255, 0, 0, 0.5),
  ),
  background: (
    base: #0e0e0e,
  ),
);

@function getColor($color, $variant) {
  @return map-get(map-get($colorMap, $color), $variant);
}

@mixin color($color, $variant) {
  color: getColor($color, $variant);
}

/** Spacing Variables **/
$spaceMap: (
  xxxxs: 2px,
  xxxs: 4px,
  xxs: 8px,
  xs: 12px,
  s: 16px,
  m: 24px,
  l: 32px,
  xl: 48px,
  xxl: 64px,
  xxxl: 96px,
  xxxxl: 128px,
  xxxxxl: 192px,
  xxxxxxl: 258px,
);

@function getSpace($size) {
  @return map-get($spaceMap, $size);
}

/** Text Variables **/
$textSizeMap: (
  largeTitle: 32px,
  title1: 32px,
  title2: 22px,
  title3: 20px,
  heading1: 16px,
  heading2: 14px,
  body1: 16px,
  body2: 14px,
  button: 13px,
  caption1: 12px,
  caption2: 11px,
);

$textLineHeightMap: (
  largeTitle: 42px,
  title1: 36px,
  title2: 30px,
  title3: 28px,
  heading1: 24px,
  heading2: 20px,
  body1: 24px,
  body2: 20px,
  button: 18px,
  caption1: 16px,
  caption2: 14px,
);

$textLetterSpacingMap: (
  largeTitle: -0.2px,
  title1: 0.6px,
  title2: 0.3px,
  title3: 0px,
  heading1: 0px,
  heading2: 0px,
  body1: 0px,
  body2: 0px,
  button: 0.8px,
  caption1: 0px,
  caption2: 0.4px,
);

$textWeightMap: (
  largeTitle: 500,
  title1: 500,
  title2: 600,
  title3: 500,
  heading1: 600,
  heading2: 600,
  body1: 400,
  body2: 400,
  button: 600,
  caption1: 400,
  caption2: 500,
);

$textTextTransformMap: (
  largeTitle: none,
  title1: uppercase,
  title2: uppercase,
  title3: none,
  heading1: none,
  heading2: none,
  body1: none,
  body2: none,
  button: uppercase,
  caption1: none,
  caption2: uppercase,
);

@mixin font($type: "body") {
  font-size: map-get($textSizeMap, $type);
  font-weight: map-get($textWeightMap, $type);
  line-height: map-get($textLineHeightMap, $type);
  letter-spacing: map-get($textLetterSpacingMap, $type);
  text-transform: map-get($textTextTransformMap, $type);
}
