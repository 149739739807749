@import "../../theme/variables.scss";

// more container
.moreContainer{
  padding: 0px 15px;
}

.moreCard {
  margin: 0px;
  margin-top: getSpace("xxl");
  margin-bottom: getSpace("l");
  padding: getSpace("s");
  border-radius: getSpace("xxs");
  display: flex;
  align-items: center;
  background: #{getColor("elevation", "a01")};

  .icon {
    margin-right: getSpace("m");
    padding: getSpace("xs");
    background: #{getColor("elevation", "a02")};

    .moreCardIonThumbnailIcon {
      @include color("whiteColor", "a64");
    }
  }
}

.moreLogoutButton {
  width: 100%;
  --background: #ffffff00;
  color: #{getColor("whiteColor", "a96")} !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: getSpace("xxs");
  height: 54px;
  margin-top: 12%;
  margin-bottom: 5%;
}

.moreNeedHelp {
  margin-bottom: getSpace("xs");
}

.moreNeedHelpText {
  margin-bottom: getSpace("m");
}
