@import "./theme/fonts";
@import "./theme/variables";
@import "./theme/ionic";
@import "./theme/forms";

ion-modal {
  --ion-backdrop-opacity: 0.9 !important;
}

.sc-ion-modal-ios-h, .sc-ion-modal-md,.sc-ion-alert-md  {
  --backdrop-opacity: 0.9 !important;
}


.ionTabBar {
  height: getSpace("xxl") !important;
  --background: #{getColor("glass", "medium")} !important;
  backdrop-filter: blur(250px) !important;
  border-top: 0.5px solid #{getColor("utility", "outline")} !important;
  display: inherit;
}

.ionTabButton {
  margin-top: getSpace("xxxs");
  background: transparent !important;
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Portrait)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .ionTabBar {
    height: getSpace("xl") !important;
  }
  .ionTabLabel {
    font-size: 12px !important;
  }
}
