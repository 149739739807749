@import "../../theme/variables.scss";

.createNewSceneTitleCol {
  text-align: end !important;
}

.createNewSceneTitle {
  white-space: pre-line;
  color: #{getColor("primary", "base")} !important;
  margin-bottom: 12px !important;
  margin-top: 5px !important;
  text-align: end !important;
  cursor: pointer;
}

.sceneHeaderRow {
  margin-right: 5px !important;
}

.sceneHeader {
  height: fit-content;
}

.sceneNameTitle {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-bottom: 12px;
  /* margin-top: 5px !important; */
}

.createNewSceneTitleButton {
  border-color: transparent !important;
  --box-shadow: none !important;
  --ripple-color: transparent !important;
  --padding-bottom: 0px;
  height: 30px !important;
  width: fit-content !important;
}
