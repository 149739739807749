@import "../../theme/variables.scss";

#groupAccessory img {
  max-width: none;
}

#groupAccessory .groupMoreIcon {
  width: getSpace("m") !important;
  cursor: pointer;
}

#groupAccessory .groupMoreIconDisabled {
  width: getSpace("m") !important;
  cursor: not-allowed;
  filter: brightness(0.4);
}

.popover-content {
  left: auto;
  width: max-content !important;
}

.groupModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 600px !important;
    border-radius: getSpace("xxs") !important;
  }
}

.animationModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 600px !important;
    border-radius: getSpace("xxs") !important;
  }
}

.streamModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 600px !important;
    border-radius: getSpace("xxs") !important;
  }
}

.accessoryMenuButtonMobile {
  font-size: 18px !important;
  --background: #{getColor("whiteColor", "a64")} !important;
  --button-background: #{getColor("whiteColor", "a64")} !important;
  --button-color: #{getColor("elevation", "a00")} !important;
  --color: #{getColor("elevation", "a00")} !important;
}

.accessoryMenuButtonMobile .action-sheet-button {
  font-size: 18px !important;
  border-bottom: 1px solid;
}

.accessoryMenuButtonMobile .action-sheet-icon {
  margin-right: getSpace('xs') !important;
  font-size:24px  !important
}

.accessoryMenuButtonMobile .action-sheet-cancel {
  font-size: 18px !important;
  color: #{getColor("critical", "base")} !important;
  border-bottom: 0px;
}

.action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: unset !important;
  opacity: unset !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios{
  justify-content:left !important
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .groupModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .animationModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .streamModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .groupModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .animationModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .streamModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .groupModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .animationModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .streamModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .groupModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .animationModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .streamModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = 320px
*/

@media (max-width: 320px) {
  .groupModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .animationModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  .streamModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}
