@import "../../theme/variables.scss";

.accessoryListItemTypesContainer {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
}

#accessoryListItemTypes .imageContainer {
  width: 56px;
  height: 54px;
  border-radius: getSpace("xxs") !important;
  background: #{getColor("elevation", "a01")};
  border: 1px solid #{getColor("utility", "outline")} !important;
  box-sizing: border-box;
  margin-right: -10px;
}

#accessoryListItemTypes .imageContainerDisconnected {
  border: 1px solid #{getColor("utility", "disconnected")} !important;
}

#accessoryListItemTypes .numberCircleDisconnected {
  border: 1px solid #{getColor("utility", "disconnected")} !important;
}
#accessoryListItemTypes .numberCircleConnected {
  border: 1px solid #{getColor("utility", "outline")} !important;
}

#accessoryListItemTypes .numberCircle {
  display: flex;
  align-items: center;
  align-content: center;
  border-radius: 8%;
  padding: 21px !important;
  width: 56px;
  height: 54px;
  background: #{getColor("elevation", "a01")} !important;
  margin-right: -5px;
}

#accessoryListItemTypes .disconnectedImage {
  align-self: flex-end;
  z-index: 1;
  margin-top: 50px;
  height:20px;
  width:20px
}

