@import "../../theme/variables.scss";

.primary {
  color: #{getColor("darkColor", "a96")} !important;
  --background: #{getColor("primary", "base")};
  --background-activated-opacity: 64%;
  @include font("button");
  border-radius: 30px !important;
  min-width: 48px !important;
}

.secondary {
  color: #{getColor("darkColor", "a96")} !important;
  --background: #{getColor("whiteColor", "a96")};
  --background-activated: #{getColor("whiteColor", "a64")};
  @include font("button");
  border-radius: getSpace("xxs") !important;
}

.tertiary {
  color: #{getColor("whiteColor", "a96")} !important;
  --background: none !important;
  --border-color: #{getColor("whiteColor", "a96")} !important;
  --border-width: 1px;
  --border-style: solid;
  @include font("button");
  border-radius: getSpace("xxs") !important;
}
