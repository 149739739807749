@import "../../../theme/variables.scss";

.pulseContainer {
  display: flex;
  flex-direction: column;
  padding-top: getSpace("l") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  height: 100%;
}

#animationDuration .intervalHeading {
  margin-top: getSpace("m") !important;
  margin-bottom: getSpace("xxs") !important;
}

#animationDuration .durationSelector {
  display: flex;
  align-items: center;
  align-content: center;
  background: #{getColor("elevation", "a01")} !important;
  border-radius: getSpace("xxs");
  height: 56px !important;
  margin-bottom: getSpace("xs") !important;
  padding: 3%;
}

#animationDuration .durationTitle {
  flex: auto;
}

#animationDuration .durationNumber {
  margin-right: getSpace("xxxs") !important;
  align-self: center;
  text-align: right;
  width: 12%;
}

#animationDuration .durationField {
  background: transparent;
  border: none;
}

#animationDuration .durationTxt {
  padding-left: 4%;
  padding-right: 4%;
}

#colorAnimation .colorHeading {
  margin-bottom: getSpace("xxs") !important;
}

#colorAnimation .colorPicker {
  margin-bottom: getSpace("xs") !important;
  height: 56px !important;
}

#colorAnimation .colorTxt {
  padding-left: 4%;
  padding-right: 4%;
}
