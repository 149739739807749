@import "../../../theme/variables.scss";

.streamContainer {
  display: flex;
  flex-direction: column;
  padding-top: getSpace("l") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  height: 100%;
}

#imageStreamMedia .streamSelectorBox {
  height: 232px;
  width: 343px;
  border: 1px solid getColor("utility", "separator") !important;
  border-radius: #{getSpace("xxs")};
  align-self: center;
}
#imageStreamMedia .streamImageBox {
  height: 232px;
  width: 343px;
  border-radius: #{getSpace("xxs")};
  align-self: center;
}

.addStreamContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 69px;
}

#imageStreamMedia .addMark {
  height: getSpace("xxl");
  width: getSpace("xxl");
  padding: getSpace("xs");
  border: 1px solid getColor("utility", "separator") !important;
  border-radius: #{getSpace("xxs")};
  background: #{getColor("elevation", "a01")};
  text-align: center;
}

.createStream {
  border-color: transparent !important;
  --box-shadow: none !important;
  --ripple-color: transparent !important;
}

#imageStreamMedia .addStreamMarkPosition {
  height: auto;
  width: auto;
  margin-left: 4px;
}

.addStreamIcon {
  position: relative;
  color: #{getColor("whiteColor", "a64")} !important;
  height: getSpace("m") !important;
  width: getSpace("m") !important;
}

.addStreamText {
  width: max-content;
  position: relative;
  margin-top: getSpace("xs");
}

.addImageText {
  text-transform: uppercase;
  color: #{getColor("whiteColor", "a64")} !important;
}

.viewStreamContainer {
  align-items: center;
}

.closeButtonContainer {
  position: relative;
}

#imageStreamMedia .streamIconContainer {
  text-align: center;
  img {
    height: 343px;
    width: 343px;
    border-radius: #{getSpace("xxs")};
  }
}

#imageStreamMedia .streamImage {
  height: getSpace("m");
  width: getSpace("m");
}

#imageStreamMedia {
  ion-fab-button {
    --background: #{getColor("glass", "thick")} !important;
    --background-hover: #{getColor("glass", "thick")} !important;
    --background-activated: #{getColor("glass", "thick")} !important;
    --background-focused: #{getColor("glass", "thick")} !important;
    --close-icon-font-size: #{getSpace("m")} !important;
    height: 44px;
    width: 44px;
    --border-radius: #{getSpace("xxs")} !important;
  }
}

#imageStreamMedia .streamMetaInfo {
  border-top: 1px solid getColor("utility", "separator") !important;
  position: relative;
  top: 100px;
  padding-left: 20px;
}

.streamMetaName {
  margin-top: 12px;
}

.streamName {
  color: #{getColor("whiteColor", "a96")} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mediaModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 550px !important;
    border-radius: getSpace("xxs") !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
  #imageStreamMedia .streamIconContainer {
    img {
      width: 100%;
    }
  }
  #imageStreamMedia .streamSelectorBox {
    width: 98%;
  }
  
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
  #imageStreamMedia .streamSelectorBox {
    width: 98%;
  }
  #imageStreamMedia .streamIconContainer {
    img {
      width: 100%;
    }
  }
  #imageStreamMedia .streamImageBox {
    width: 100%;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
  #imageStreamMedia .streamIconContainer {
    img {
      width: 100%;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = 320px
*/

@media (max-width: 320px) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}
