@import "../../theme/variables.scss";

#login-page ion-row {
  align-items: center;
  justify-content: center;
}

.content {
  height: 100vh;
  display: flex;
  align-items: center;
}

.emailRow {
  margin-top: getSpace("xl");
  margin-bottom: getSpace("l") !important;
}

#login-page .ionItem {
  padding: 0px !important;
  --padding-start: 0px !important;
  --inner-padding-end: 0px !important;
}

.ionInput {
  border-bottom: 1px solid getColor("utility", "separator") !important;
}

.ionInput input {
  $InputHeight1: getSpace("xl");
  $InputHeight2: getSpace("xxs");
  height: $InputHeight1 + $InputHeight2 !important;
}

.errorRow {
  white-space: pre-line !important;
  margin-left: 15% !important;
  margin-top: getSpace("xxxxs") !important;
}

.submitRow {
  margin-top: getSpace("xl");
  margin-bottom: getSpace("xxs");
}

#login-page ion-item {
  width: var(--loginPageElementsWidth, 256px);
  max-height: 32vh;
}

.buttonSubmit {
  $height: getSpace("xl");
  width: var(--loginPageElementsWidth, 256px) !important;
  height: $height - 4px !important;
  margin: 0px !important;
  border-radius: getSpace("xxxs") !important;
}

.buttonForgotPassword {
  text-decoration: none;
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: getColor("primary", "base");
  color: getColor("primary", "base");
  --box-shadow: none !important;
  $height: getSpace("xl");
  width: var(--loginPageElementsWidth, 256px) !important;
  height: $height - 4px !important;
  margin: 0px !important;
}

.loginAccountError {
  margin-right: getSpace("s");
}

.loginEmptyError {
  margin-right: getSpace("xxl");
  margin-top: getSpace("xxxxs") !important;
}

.passwordEmptyError {
  margin-right: getSpace("xxl");
}

/*
  ##Device = Desktops
  ##Screen = 992px to higher resolution desktops
*/

@media (min-width: 992px) {
  #login-page {
    --loginPageElementsWidth: 320px;
  }
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px getColor("background","base") inset !important;
    -webkit-text-fill-color: #fff;
}