@import "../../theme/variables.scss";

.homeContent {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.hiddenHomeField {
  height: 0px;
  min-height: 0px;
}

.grid {
  margin-left: getSpace("s");
  margin-right: getSpace("s");
  margin-top: getSpace("xl");
}

.headerRow {
  border-bottom: 1px solid getColor("utility", "separator") !important;
  padding-bottom: getSpace("s");
}

.wallCardRow {
  margin-top: getSpace("xs");
}

.wallsTitle {
  text-transform: uppercase !important;
  margin: 24px 0 16px 16px;
}

.wallSpinner {
  display: table;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .wallCardRow {
    justify-content: center !important;
    padding-inline-start: unset !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 414px to 480px
*/

@media (min-width: 411px) and (max-width: 480px) {
  .wallsTitle {
    margin: 24px 0 16px 24px;
  }
}
