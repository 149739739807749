@import "../../theme/variables.scss";

.uploadSection {
  display: flex;
  flex-direction: row;
  /* flex: 1;
  min-height: 72px;
  height: 72px; */
  padding-top: getSpace("xs");
  padding-bottom: getSpace("xs");
  border-bottom: 1px solid #{getColor("utility", "separator")};
}
.uploadButton {
  --color: transparent !important;
  --color-hover: transparent !important;
  --color-activated: transparent !important;
  --color-focused: transparent !important;
  --ripple-color: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
  --box-shadow: none !important;

  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;
  margin-left: getSpace("s");
  min-height: getSpace("xl");
  height: getSpace("xl");
}

.uploadSectionIcon {
  min-height: getSpace("xl");
  min-width: getSpace("xl");
  height: getSpace("xl");
  width: getSpace("xl");
  background: #{getColor("glass", "primarythin")} !important;
  border-radius: getSpace("xxs");
  padding: getSpace("xs");
  margin-right: getSpace("s");
}

.uploadIcon {
  height: getSpace("m");
  width: getSpace("m");
  color: #{getColor("primary", "base")} !important;
}

.uplodFile {
  color: #{getColor("primary", "base")} !important;
}

.uploadSectionText {
  align-self: center;
}

.mediaUploadProgressModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 650px !important;
    border-radius: getSpace("xxs") !important;
    top: 160px;
    position: absolute;
    .ion-page {
      display: initial !important;
      .spinner {
        margin-top: 30% !important;
        margin-left: 46% !important;
      }
    }
  }
}

.uploadProgress {
  text-align: center !important;
  margin-top: getSpace("xs");

}

.mediaUploadProgress {
  color: #{getColor("whiteColor", "a96")} !important;
}
