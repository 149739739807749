@import "../../theme/variables.scss";

.scene {
  cursor: pointer;
}

.sceneGridNoScrolling {
  margin-bottom: 8px;
  margin-right: 14px;
}

.sceneListItem {
  display: flex;
  flex-direction: row;
  padding: getSpace("xs");
  background: #{getColor("elevation", "a02")} !important;
  min-height: 100%;
}

.sceneListItemCol {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.sceneNameSchedule {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: getSpace("s");
  justify-content: center;
}

.sceneNameScheduleButton {
  --box-shadow: none;
  margin: 0px !important;
  height: auto;
  white-space: normal;
  --padding-start: 0px;
}

.sceneContent {
  flex-grow: 1;
  text-align: start;
}

.sceneStateBoxDiv {
  width: 56px !important;
  height: 56px !important;
  align-self: center;
}

.sceneStateBox {
  width: 56px !important;
  height: 56px !important;
  margin: 0px !important;
  --box-shadow: none;
  border-radius: 50% !important;
  padding: 4px;
}

.sceneStateBoxActive {
  background: #{getColor("glass", "primarythin")} !important;
}

.sceneStateBoxInActive {
  background: #{getColor("glass", "medium")} !important;
}

.sceneStateIcon {
  width: getSpace("m") !important;
  height: getSpace("m") !important;
}

.sceneStateIconMobile {
  width: getSpace("s") !important;
  height: getSpace("s") !important;
}

.sceneName {
  white-space: pre-wrap;
  word-break: break-word;
}

.sceneSchedule {
  white-space: pre-wrap;
  word-break: break-all;
}

.activeSceneName {
  color: #{getColor("primary", "base")} !important;
}

.inactiveSceneName {
  color: #{getColor("whiteColor", "a96")} !important;
}

.activeSceneStateIcon {
  color: #{getColor("primary", "base")} !important;
}

.inactiveSceneStateIcon {
  color: #{getColor("whiteColor", "a96")} !important;
}

.sceneActionsDivider {
  width: 1px;
  height: auto;
  margin-top: -8px;
  margin-bottom: -8px;
  background: #{getColor("elevation", "a00")} !important;
}

.sceneActions {
  text-align: center;
  align-self: center;
  margin-left: getSpace("m") !important;
  margin-right: getSpace("s");

  img {
    max-width: unset;
  }
}

.sceneTitleRow {
  border-radius: getSpace("xxs");
}

.activeSceneTitleRow {
  background: #{getColor("primary", "dark")} !important;
}

.inActiveSceneTitleRow {
  background: #{getColor("elevation", "a01")} !important;
}

.sceneIcon {
  width: getSpace("m");
}

.activeSceneIcon {
  filter: brightness(1);
}

.inActiveSceneIcon {
  filter: brightness(0.5);
}

.listItemAlignment {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.moreIconCol {
  padding: getSpace("s") !important;
  text-align: center !important;
}

.sceneNameRow {
  word-break: break-word;
}

.sceneIconCol {
  margin: getSpace("xxs") !important;
  padding: getSpace("s") !important;
  background: #{getColor("glass", "primarythin")} !important;
  text-align: center !important;
  border-radius: getSpace("xxs") !important;
}

.moreIcon {
  width: getSpace("m") !important;
  height: getSpace("m") !important;
}

.MuiIconButton-root {
  padding: 0px !important;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .ionColSceneListItem {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .sceneNameSchedule {
    justify-content: flex-start !important;
    margin-left: unset !important;
  }

  .sceneStateBoxDiv {
    align-self: flex-start !important;
  }

  .sceneActions {
    align-self: flex-start !important;
    margin-right: 0px !important;
    height: 32px;
    width: 32px;
    background: #{getColor("elevation", "a03")} !important;
    border-radius: #{getSpace("xxs")};
    padding: #{getSpace("xxxs")};
  }

  .sceneListItem {
    width: 272px !important;
    margin-right: getSpace("xs");
    flex-direction: column !important;
    background: #{getColor("elevation", "a02")} !important;
  }

  .sceneListItemCol {
    height: 52px !important;
    flex-grow: 0 !important;
  }

  .sceneNameCol {
    width: 50%;
  }

  .sceneStateBox {
    width: 32px !important;
    height: 32px !important;
    padding: 4px;
  }

  .activeSceneStateIcon {
    width: 16px !important;
    height: 16px !important;
    margin-right: 2px;
  }

  .inactiveSceneStateIcon {
    width: 16px !important;
    height: 16px !important;
  }
}
