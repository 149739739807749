@import "../../theme/variables.scss";

.alert-wrapper {
  background: #{getColor("background", "base")} !important;
  border-radius: 14px !important;
}
.sc-ion-alert-ios-h {
  --backdrop-opacity: 0.9 !important;
}

.alert-button-group.sc-ion-alert-md {
  justify-content: space-around !important;
}

.alert-input.sc-ion-alert-ios {
  font-size: larger !important;
}
