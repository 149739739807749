@import "../../theme/variables.scss";

.card {
  background: getColor("elevation", "a01");
  margin: 0px 0px 4px 0px;
  cursor: pointer;
}

.cardContent {
  padding: 20px;
}

.cardTitle {
  font-size: medium;
  margin-top: 20px;
  color: getColor("whiteColor", "a96");
}

.cardSubtitle {
  padding-top: getSpace("xxxs");
  @include font("body2");
  color: getColor("whiteColor", "a64");
}

.cardInfo {
  margin-top: getSpace("xxxs");
}

.wallImage {
  width: 100%;
  height: 100%;
}

.statusCol {
  display: flex;
  justify-content: flex-end;
  align-items: center !important;
}
.wallStatus {
  @include font("body2");
  text-transform: uppercase;
  margin-left: 6px;
  margin-right: 6px;
}

.on {
  color: getColor("primary", "base");
}

.off {
  color: getColor("whiteColor", "a64");
}

.offline {
  color: #{getColor("critical", "base")} !important;
}

.disconnected {
  @include font("body2");
  color: getColor("darkGreyColor", "base");
  text-transform: uppercase;
  margin: 6px;
}

.wallCardCol {
  display: flex;
}

.wallListItem {
  background: getColor("elevation", "a01");
  min-width: 108%;
  height: auto;
  border-radius: getSpace("xxs");
  min-height: 100%;
}

.wallListItemButton {
  cursor: pointer;
  margin: 0px;
  width: 353px;
  height: auto;
  border-radius: getSpace("xxs");
  margin-bottom: getSpace("xs");
  margin-left: getSpace("s");
  margin-right: getSpace("s");
  --box-shadow: none;
}

.wallInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: getSpace("xs");
  padding-bottom: getSpace("xs");
  padding-left: getSpace("s");
  min-width: 100%;
}

.wallName {
  margin-bottom: getSpace("xxxxs");
  align-self: flex-start;
  color: getColor("whiteColor", "a96");
  word-break: break-all;
  white-space: pre-wrap;
  width: 75%;
  text-align: start;
}

.wallAccessories {
  margin-bottom: getSpace("s");
  align-self: flex-start;
}
.wallState {
  width: fit-content;
  align-self: flex-start;
  text-align: start;
  border-radius: #{getSpace("xxs")};
  height: 20px;
  align-self: flex-end;
}

.partialStateTxt {
  color: getColor("orangeColor", "base");
}

.wallStateOn {
  background: #{getColor("glass", "primarythin")} !important;
}

.wallStateOff {
  background: #{getColor("glass", "whitethin")} !important;
}

.wallStateOffline {
  background: #{getColor("glass", "criticalthin")} !important;
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) {
  .cardContent {
    height:220px
  }
  .wallImage {
    width: 100%;
    height: 40%;
  }
  .wallState { 
    position: absolute;
    bottom:15px
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 412px to 480px
*/

@media (min-width: 412px) and (max-width: 480px) {
  #wallImageSmallDevice {
    width: 100% !important;
    display: flex;
  }
  #wallImageSmallDevice img {
    width: 200px;
    height: 200px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 303px !important;
  }

  .overlay {
    width: 200px !important;
    height: 200px !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 303px !important;
    opacity: 0.4;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 376px to 411px
*/

@media (min-width: 376px) and (max-width: 411px) {
  #wallImageSmallDevice {
    width: 100% !important;
    display: flex;
  }
  #wallImageSmallDevice img {
    width: 200px;
    height: 200px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 303px !important;
  }

  .overlay {
    width: 200px !important;
    height: 200px !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 303px !important;
    opacity: 0.4;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 361px to 375px
*/

@media (min-width: 361px) and (max-width: 375px) {
  #wallImageSmallDevice {
    width: 100% !important;
    display: flex;
  }
  #wallImageSmallDevice img {
    width: 200px;
    height: 200px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 284px !important;
  }

  .overlay {
    width: 200px !important;
    height: 200px !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 284px !important;
    opacity: 0.4;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 321px to 360px
*/

@media (min-width: 321px) and (max-width: 360px) {
  #wallImageSmallDevice {
    width: 100% !important;
    display: flex;
  }
  #wallImageSmallDevice img {
    width: 200px;
    height: 200px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 264px !important;
  }

  .overlay {
    width: 200px !important;
    height: 200px !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 264px !important;
    opacity: 0.4;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px
*/

@media (max-width: 320px) {
  #wallImageSmallDevice {
    width: 100% !important;
    display: flex;
  }
  #wallImageSmallDevice img {
    width: 200px;
    height: 200px;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 228px !important;
  }

  .overlay {
    width: 200px !important;
    height: 200px !important;
    transform: rotate(45deg) !important;
    position: absolute !important;
    top: -48px !important;
    left: 228px !important;
    opacity: 0.4;
  }
  .wallName {
    width: 90% !important;
  }
}
