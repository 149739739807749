@import "../../theme/variables.scss";

.ionSegment {
  width: 166px;
  height: 32px;
  background: #{getColor("glass", "thick")} !important;
}
.segment-button-checked {
  --color-checked: #{getColor("primary", "base")} !important;
  --indicator-color: #{getColor("elevation", "a03")} !important;
}

.ionContent {
  display: flex;
  flex-direction: column;
  // padding-bottom: 180px !important;
  overflow-y: auto !important;

}

.mediaIonContent {
  height: 550px;
}

/*
    ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 376px to 411px
*/

@media (min-width: 768px) and (max-height: 1366px) {
  .mediaIonContent {
    height: 400px;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen width/ height = max 375px / max 670px
*/

@media  (max-width: 375px) and (max-height: 670px) {
  .mediaIonContent {
    height: 500px;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen width/ height = max 414px / B/w 671px to 700px
*/

@media (max-width:414px) and (min-height: 671px) and (max-height: 736px) {
  .mediaIonContent {
    height: 590px;
  }
}


/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen width/ height = max 411px / B/w 737px to 823px
*/
@media (max-width:414px) and   (min-height: 737px) and (max-height: 823px) {
  .mediaIonContent {
    height: 660px;
  }
}
