@import "../../theme/variables.scss";

.largeTitle {
  @include font("largeTitle");
}

.title1 {
  @include font("title1");
}

.title2 {
  @include font("title2");
}

.title3 {
  @include font("title3");
}

.heading1 {
  @include font("heading1");
}

.heading2 {
  @include font("heading2");
}

.body1 {
  @include font("body1");
}

.body2 {
  @include font("body2");
}

.button {
  @include font("button");
}

.caption1 {
  @include font("caption1");
}

.caption2 {
  @include font("caption2");
}

/** Colors **/
.primaryColor {
  @include color("primary", "base");
}

.critical {
  @include color("critical", "base");
}

.white32 {
  @include color("whiteColor", "a32");
}

.white64 {
  @include color("whiteColor", "a64");
}

.default {
  @include color("whiteColor", "a96");
}
