@import "../../../theme/variables.scss";

.mediaSection {
  display: flex;
  flex-direction: row;
  flex: auto;
  margin-top: getSpace("xs");
  border-bottom: 1px solid #{getColor("utility", "separator")};
}

.media {
  flex: 1;
  margin-bottom: getSpace("xs");
}

.mediaButton {
  --ripple-color: transparent !important;
  --background-activated: transparent !important;
  --background-focused: transparent !important;
  --background-hover: transparent !important;
  --background-focused-opacity: 1;
  --color-activated: transparent !important;
  --color-focused: transparent !important;
  --color-hover: transparent !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: 0px !important;

  margin-left: getSpace("s");
  min-height: getSpace("xl");
  height: getSpace("xl");
}

.mediaSectionIcon {
  min-height: getSpace("xl");
  min-width: getSpace("xl");
  height: getSpace("xl");
  width: getSpace("xl");
  background: #{getColor("elevation", "a01")} !important;
  border-radius: getSpace("xxs");
  padding: getSpace("xs");
  margin-right: getSpace("s");
}

.mediaIcon {
  height: getSpace("m");
  width: getSpace("m");
}

.mediaSection .media-platform-icon{
  margin-top: 13%;
  z-index: 1;
  margin-left: -24px;
  margin-right: getSpace("s");
  height:14px;
  width: 14px;
  }

.mediaSectionText {
  align-self: center;
  text-align: start;
  width: 250px;
}

.mediaName {
  color: #{getColor("whiteColor", "a96")} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mediaMetadata {
  color: #{getColor("whiteColor", "a64")} !important;
}

.mediaMore {
  margin-right: getSpace("s") !important;
  align-self: center;
}

.mediaMoreIcon {
  width: getSpace("m") !important;
  cursor: pointer;
  filter: brightness(0.6);
}

 .mediaMoreIconDisabled {
  width: getSpace("m") !important;
  cursor: not-allowed;
  filter: brightness(0.4);
}

.mediaMenuButtonMobile {
  font-size: 18px !important;
  --background: #{getColor("whiteColor", "a64")} !important;
  --button-background: #{getColor("whiteColor", "a64")} !important;
  --button-color: #{getColor("elevation", "a00")} !important;
  --color: #{getColor("elevation", "a00")} !important;
}

.mediaMenuButtonMobile .action-sheet-button {
  font-size: 18px !important;
  border-bottom: 1px solid;
}

.mediaMenuButtonMobile .action-sheet-icon  {
  margin-right: getSpace('xs') !important
}

.mediaMenuButtonMobile .action-sheet-cancel {
  font-size: 18px !important;
  color: #{getColor("critical", "base")} !important;
  border-bottom: 0px;
}

.action-sheet-cancel.sc-ion-action-sheet-ios::after {
  background: unset !important;
  opacity: unset !important;
}

.action-sheet-group{
  border-radius: 0px !important;
margin-bottom: 0px !important;
justify-content: left !important;
}

.action-sheet-button-inner.sc-ion-action-sheet-ios{
  justify-content:left !important
}

.mediaListSpinner {
  float: right;
  margin-right: 10px;
  height: 20px;
  margin-top: 12%;
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Portrait)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .mediaListSpinner {
    margin-top: 20%;
  }
  .mediaSectionText {
    width: 250px;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .mediaListSpinner {
    margin-top: 30%;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 374px
*/

@media (min-width: 320px) and (max-width: 374px) {
  .mediaListSpinner {
    margin-top: 30%;
  }
  .mediaSectionText {

    width: 160px;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 375px to 413px
*/

@media (min-width: 375px) and (max-width: 413px) {
  .mediaListSpinner {
    margin-top: 30%;
  }
  .mediaSectionText {

    width: 170px;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 376px to 411px
*/

@media (min-width: 376px) and (max-width: 411px) {
  .mediaListSpinner {
    margin-top: 25%;
  }
  .mediaSectionText {

    width: 200px;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 414px to 480px
*/

@media (min-width: 414px) and (max-width: 480px) {
  .mediaListSpinner {
    margin-top: 25%;
  }
  .mediaSectionText {

    width: 200px;
  }
}
