@import "../../../theme/variables.scss";

#createEditStream .groupHeading {
  margin-top: getSpace("m") !important;
}

#createEditStream .accessoryItemList {
  margin-left: -5% !important;
  margin-right: -2% !important;
  background: #{getColor("elevation", "a01")} !important;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: getSpace("s") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  padding-bottom: getSpace("m") !important;
  flex-wrap: wrap;
  background: #{getColor("elevation", "a01")} !important;
}

.container div {
  border: 0px #000 solid;
}
.streamHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: getSpace("m") !important;
}

.streamHeaderIcon {
  height: getSpace("xl") !important;
  width: getSpace("xl") !important;
  background: #{getColor("elevation", "a00")} !important;
  border-radius: getSpace("xxs");
}

.accessoryIcon {
  margin-top: 17%;
  margin-left: 18%;
}

#createEditStream .streamTypeButton {
  background: #{getColor("elevation", "a03")} !important;
  border-radius: getSpace("xxs");
  $height-max: getSpace("xxl");
  $height-min: getSpace("xxs");
  height: calc(#{$height-max} - #{$height-min}) !important;
  justify-content: space-between;
  --box-shadow: none !important;
  cursor: pointer;
}

#createEditStream .streamType {
  display: flex;
  flex-basis: 100%;
}

#createEditStream .streamTypeTxt {
  flex-basis: 100%;
  text-align: start;
  color: #{getColor("whiteColor", "a96")} !important;
}

#createEditStream .streamTypeArrowDiv {
  align-self: center;
}

#createEditStream .streamTypeArrow {
  height: getSpace("m") !important;
  width: getSpace("m") !important;
  color: getColor("whiteColor", "a64");
}

#createEditStream .streamMsg {
  justify-content: space-around;
  padding: 20%;
  text-align: center;
}

#createEditStream .dummyImage {
  height: getSpace("xl");
  color: #{getColor("whiteColor", "a32")} !important;
}

#createEditStream .errorRow {
  background: #{getColor("elevation", "a01")} !important;
}

.streamTypeModal {
  .modal-wrapper {
    display: block;
    width: 420px !important;
    height: 600px !important;
    border-radius: getSpace("xxs") !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
  */

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .streamTypeModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  #createEditStream {
    overflow-y: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .streamTypeModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  #createEditStream {
    overflow-y: auto !important;
  }

}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  .streamTypeModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .streamTypeModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
  #createEditStream {
    overflow-y: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = 320px
*/

@media (max-width: 320px) {
  .streamTypeModal {
    .modal-wrapper {
      height: 99% !important;
    }
  }
}
