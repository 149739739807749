@import "../../theme/variables.scss";

.accessoryListBrightnessContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px !important;
}

#accessoryListItemBrightness .accessoryListBrightnessInternalContainer {
  max-width: 800px;
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: right;
  align-content: flex-end;
  flex-wrap: nowrap;
  align-self: flex-end;
}

#accessoryListItemBrightness .accessoryListItemPlaceholder {
  width: 37px !important;
}

#accessoryListItemBrightness .accessoryListItemBrightnessSlider {
  padding-left: #{getSpace("m")};
  padding-right: #{getSpace("xxs")};
  display: flex;
  flex-direction: row;
  width: 100% !important;
}

#accessoryListItemBrightness .accessoryListItemSlider {
  flex: auto;
}

#accessoryListItemBrightness .sliderIcon {
  margin-right: getSpace("s");
  width: 18px;
  height: 18px;
  background: url("../../assets/images/wb_sunny-white.svg") no-repeat center;
  filter: brightness(0.6);
}

#accessoryListItemBrightness .accessoryListItemBrightnessPercentage {
  text-transform: uppercase;
}

#accessoryListItemBrightness .accessoryListItemBrightnessOff {
  text-transform: uppercase;
  margin-right: getSpace("xxs") !important;
}


#accessoryListItemBrightness .brightnessPicker1 {
  height: 56px !important;
}

#accessoryListItemBrightness .brightnessPickerDiv {
  position: relative;
  width: 100% !important;
  margin-right: #{getSpace("xxs")};
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .accessoryListBrightnessContainer {
    justify-content: flex-start !important;
  }

  #colorPicker .colorCircleButton {
    --ripple-color: transparent !important;
    --background-activated: transparent !important;
  }
}
