@import "../../../theme/variables.scss";

#animationTypes .modalContent {
  --background: #{getColor("elevation", "a00")} !important;
}

.animationTypeContainer {
  display: flex;
  padding-top: getSpace("s") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  padding-bottom: getSpace("m") !important;
  flex-wrap: wrap;
  background: #{getColor("elevation", "a00")} !important;
  align-items: center;
}

#animationTypes .animationTypeButton {
  justify-content: space-around;
  align-items: center;
  border-radius: getSpace("xxs");
  width: 166px !important;
  height: 124px !important;
  margin: getSpace("xs") !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
}

#animationTypes .animationTypeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#animationTypes .animationTypeDivTxt {
  justify-content: center;
  text-align: center;
}

#animationTypes .animationTypeTxt {
  margin-top: getSpace("m")
}

#animationTypes .animationTypeMsg {
  text-align: center;
  padding-left: getSpace("m");
  padding-right: getSpace("m");
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  #animationTypes {
    overflow-y: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
  #animationTypes .animationTypeButton {
    width: 140px !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 413px
*/

@media (min-width: 320px) and (max-width: 413px) and (orientation: landscape) {
  #animationTypes {
    overflow-y: auto !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 414px) and (max-width: 767px) and (orientation: landscape) {
  #animationTypes {
    overflow-y: auto !important;
  }
}
