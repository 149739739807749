@import "../../theme/variables.scss";

#createSceneAccessories .sceneName {
  color: #{getColor("whiteColor", "a64")} !important;
  border-bottom: 1px solid getColor("utility", "separator") !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 42px;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 321px) and (max-width: 480px) {
  #createSceneAccessories .sceneName {
    font-size: 24px;
  }
}
