@import "../../theme/variables.scss";

#createSceneAutomation .timeAutomation {
  margin-left: getSpace("xxs");
  margin-top: getSpace("m");
}

#createSceneAutomation .timeAutomationDesc {
  margin-left: getSpace("xxs");
  margin-top: getSpace("xxxs");
  margin-bottom: getSpace("s");
}

#createSceneAutomation .noSceneScheduleRow {
  max-width: 430px !important;
  height: getSpace("xxl") !important;
  background: getColor("elevation", "a02");
  border-radius: getSpace("xxs");
  cursor: pointer;
  align-items: center !important;
}

#createSceneAutomation .noSceneScheduleIcon {
  margin-left: getSpace("xxs") !important;
  padding: getSpace("xs") !important;
  text-align: center !important;
  border-radius: getSpace("xxs");
  background: rgba(255, 255, 255, 0.08) !important;
  backdrop-filter: blur(12px) !important;
  font-size: getSpace("m");
  max-width: getSpace("xl") !important;
  max-height: getSpace("xl") !important;
}

#createSceneAutomation .scheduleSceneIcon {
  height: getSpace("m");
}

#createSceneAutomation .activeSceneScheduleIcon {
  filter: brightness(1);
}

#createSceneAutomation .inActiveSceneScheduleIcon {
  filter: brightness(0.5);
}

#createSceneAutomation .createNewSceneScheduleTitle {
  white-space: pre-line;
  border-color: transparent !important;
  --box-shadow: none !important;
  --ripple-color: transparent !important;
  text-align: start;
  height: auto !important;
}

.scheduleSceneModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 650px !important;
    border-radius: getSpace("xxs") !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .scheduleSceneModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .scheduleSceneModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .scheduleSceneModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }

  #createSceneAutomation .noSceneScheduleRow {
    max-width: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .scheduleSceneModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = 320px
*/

@media (max-width: 320px) {
  .scheduleSceneModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}
