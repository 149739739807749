@import "../../theme/variables.scss";

#createSceneAccessories .accessoriesTitle {
  margin-top: getSpace("l");
  margin-left: getSpace("xxs");
}

#createSceneAccessories .accessoriesTitleDesc {
  margin-left: getSpace("xxs");
}
