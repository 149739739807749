@import "../../theme/variables.scss";

#createSceneSchedule {
  overflow-y: auto !important;
  min-height: 100%;
  height: auto;
}

#createSceneSchedule .scheduleSpaceTop {
  margin-top: getSpace("m");
}

#createSceneSchedule .scheduleSpaceLeft {
  margin-left: getSpace("s");
}

#createSceneSchedule .scheduleSpaceBottom {
  margin-bottom: getSpace("xxs");
}

#createSceneSchedule .repeatTopSpace {
  margin-top: getSpace("m") !important;
}

#createSceneSchedule .repeatBottomSpace {
  margin-bottom: -getSpace("xs") !important;
}

#createSceneSchedule .scheduleSceneToolbar {
  --background: #{getColor("elevation", "a03")} !important;
  height: 72px !important;
}

#createSceneSchedule .scheduleStartTimeRow {
  background: #{getColor("elevation", "a02")} !important;
  align-items: center;
}

#createSceneSchedule .scheduleStartTimeCol {
  text-align: end !important;
}

#createSceneSchedule .ionDateTime {
  @include font("body1");
  color: #{getColor("whiteColor", "a96")} !important;
}

#createSceneSchedule .weekdayItemList {
  background: #{getColor("elevation", "a01")} !important;
}

#createSceneSchedule .weekdayItem {
  --background: #{getColor("elevation", "a02")} !important;
  --color-hover: #{getColor("elevation", "a02")} !important;
  --color-focused: #{getColor("elevation", "a02")} !important;
  --color-activated: #{getColor("elevation", "a02")} !important;
}

#createSceneSchedule .weekdayLabel {
  margin-left: getSpace("s");
  color: #{getColor("whiteColor", "a96")} !important;
}

#createSceneSchedule .weekdayCheckbox {
  --background: #{getColor("elevation", "a02")} !important;
  --background-checked: #{getColor("elevation", "a02")} !important;
  --border-color: transparent !important;
  --border-width: 0px !important;
  --border-color-checked: #{getColor("elevation", "a02")} !important;
  --checkmark-color: #{getColor("primary", "base")} !important;
  margin-right: getSpace("xs");
}

#createSceneSchedule .no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

#createSceneSchedule .deleteAutomation {
  height: getSpace("m") !important;
  cursor: pointer;
  width: fit-content !important;
}

#createSceneSchedule .modalContent {
  --background: #{getColor("elevation", "a01")} !important;
}

#createSceneSchedule .buttonDelete {
  color: #{getColor("whiteColor", "a96")} !important;
  margin-top: getSpace("xl");
  border-radius: getSpace("xxs");
  border: 1px solid getColor("utility", "separator") !important;
  padding: 13px 24px 13px 24px !important;
  height: getSpace("xl");
}

#createSceneSchedule .buttonDeleteColumn {
  text-align: center !important;
  margin-top: getSpace("xxs") !important;
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  #createSceneSchedule {
    min-height: unset !important;
    height: unset !important;
  }
}
