@import "../../theme/variables.scss";

.mediaHeading{
  margin-left: getSpace("m");
  margin-top: getSpace("l");
  margin-bottom: getSpace("l");
  display: flex;
  flex-direction: row;
}
 .mediaHeading .media-platform-icon{
margin-right: getSpace("s");
align-self: center;
height: 16px;
width: 16px;
}