@import "../../theme/variables.scss";

.accessoryListColorStreamContainer {
  width: 100% !important;
  display: flex;
  justify-content: flex-end;
  padding: 0px !important;
}

#accessoryListItemStream .accessoryListItemStreamEndStreamText {
  flex: auto;
  --background: #{getColor("elevation", "a03")} !important;
  --border: 0px;
  --background-hover: #{getColor("elevation", "a03")} !important;
  --ripple-color: transparent;
  --background-activated: transparent;
}

#accessoryListItemStream .accessoryListItemStreamEndStreamButton {
  --background: #{getColor("elevation", "a03")} !important;
  --border: 0px;
  --color: #{getColor("whiteColor", "a96")} !important;
  --background-hover: #{getColor("elevation", "a03")} !important;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-top: getSpace("s");
  margin-bottom: getSpace("s");
}

#accessoryListItemStream .endStreamIcon {
  height: getSpace("m");
  width: getSpace("m");
}

#accessoryListItemStream .streamImage {
  height: getSpace("m");
  width: getSpace("m");
}
#accessoryListItemStream .accessoryListItemStreamContainer {
  --background: #{getColor("elevation", "a03")} !important;
  --border-radius: getSpace("xxs");
  --border-color: #{getColor("elevation", "a03")} !important;
  max-width: 800px;
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: right;
  align-content: flex-end;
  flex-wrap: nowrap;
  align-self: flex-end;
  border-radius: getSpace("xxs") !important;
}

#accessoryListItemStream .streamTextHeading {
  display: none;
}

#accessoryListItemStream .streamType {
  color: #{getColor("whiteColor", "a96")} !important;
}

#accessoryListItemStream .streamName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 414px to 736px
*/

@media (min-width: 411px) and (max-width: 736px) {
  .accessoryListColorStreamContainer {
    justify-content: flex-start;
    width: 100% !important;
  }
  #accessoryListItemStream .accessoryListItemStreamContainer {
    height: 56px !important;
    margin-right: getSpace("xxs") !important;
  }
  #accessoryListItemStream .accessoryListItemStreamEndStreamText {
    height: 56px !important;
    width: 205px !important;
    text-align: start;
  }

  #accessoryListItemStream .streamText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
  }
  #accessoryListItemStream .streamTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 359px
*/

@media (min-width: 320px) and (max-width: 359px) {
  .accessoryListColorStreamContainer {
    justify-content: flex-start;
    width: 100% !important;
  }
  #accessoryListItemStream .accessoryListItemStreamContainer {
    height: 56px !important;
    margin-right: getSpace("xxs") !important;
  }
  #accessoryListItemStream .accessoryListItemStreamEndStreamText {
    height: 56px !important;
    width: 205px !important;
    text-align: start;
  }

  #accessoryListItemStream .streamText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
  }
  #accessoryListItemStream .streamTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 360px to 374px
*/

@media (min-width: 375px) and (max-width: 410px) {
  .accessoryListColorStreamContainer {
    justify-content: flex-start;
    width: 100% !important;
  }
  #accessoryListItemStream .accessoryListItemStreamContainer {
    height: 56px !important;
    margin-right: getSpace("xxs") !important;
  }
  #accessoryListItemStream .accessoryListItemStreamEndStreamText {
    height: 56px !important;
    width: 205px !important;
    text-align: start;
  }

  #accessoryListItemStream .streamText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
  }
  #accessoryListItemStream .streamTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 360px to 374px
*/

@media (min-width: 360px) and (max-width: 374px) {
  .accessoryListColorStreamContainer {
    justify-content: flex-start;
    width: 81.6% !important;
  }
  #accessoryListItemStream .accessoryListItemStreamContainer {
    height: 56px !important;
    margin-right: getSpace("xxs") !important;
  }
  #accessoryListItemStream .accessoryListItemStreamEndStreamText {
    height: 56px !important;
    width: 200px !important;
    text-align: start;
  }

  #accessoryListItemStream .streamText {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: getSpace("xxs");
    margin-bottom: getSpace("xxs");
    margin-left: getSpace("xs");
  }
  #accessoryListItemStream .streamTextHeading {
    display: block !important;
    color: #{getColor("whiteColor", "a64")} !important;
  }
}
