@import "./variables";

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #00d2a6;
  --ion-color-primary-rgb: 0, 210, 166;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #00b992;
  --ion-color-primary-tint: #1ad7af;

  --ion-color-secondary: #ffffff;
  --ion-color-secondary-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #e0e0e0;
  --ion-color-secondary-tint: #ffffff;

  --ion-color-tertiary: #0e1010;
  --ion-color-tertiary-rgb: 14, 16, 16;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #0c0e0e;
  --ion-color-tertiary-tint: #262828;

  --ion-color-success: #00d2a6;
  --ion-color-success-rgb: 0, 210, 166;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00b992;
  --ion-color-success-tint: #1ad7af;

  --ion-color-warning: #ffb950;
  --ion-color-warning-rgb: 255, 185, 80;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0a346;
  --ion-color-warning-tint: #ffc062;

  --ion-color-danger: #f83a61;
  --ion-color-danger-rgb: 248, 58, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #da3355;
  --ion-color-danger-tint: #f94e71;

  --ion-color-dark: #090909;
  --ion-color-dark-rgb: 9, 9, 9;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #080808;
  --ion-color-dark-tint: #222222;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f5f5f6;
  --ion-color-light-rgb: 245, 245, 246;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8d8;
  --ion-color-light-tint: #f6f6f7;

  --ion-background-color: #0e0e0e;
  --ion-background-color-rgb: 14, 14, 14;

  --ion-text-color: #f3f3f3;
  --ion-text-color-rgb: 243, 243, 243;

  --ion-backdrop-color: #0e0e0e;
  --ion-toolbar-background: #0e0e0e;

  --ion-item-border-color: #0e1010;

  --ion-font-family: "Inter";
}
