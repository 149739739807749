@import "../../theme/variables.scss";

.accessoryItemRow {
  --ion-item-background: #{getColor("elevation", "a02")};
  padding: 0px !important;
  @media only screen and (max-width: 600px) {
    margin-bottom: #{getSpace("xxs")};
    border-radius: #{getSpace("xxs")};
  }
}

.accessoryListItemContainer {
  width: 100%;
  height: 72px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
}

#accessoryListItem .accessoryListItemId {
  padding-left: #{getSpace("m")};
  padding-right: #{getSpace("m")};
}

#accessoryListItem .accessoryType {
  box-shadow: 0px 1px 2px 0px #000000 16%, 0px 8px 24px 0px #000000 24%;
  width: 20%;
  display: flex;
}

#accessoryListItem .dummyImage {
  height: getSpace("xl");
  color: #{getColor("whiteColor", "a32")} !important;
}

#accessoryListItem .accessoryListItemContent {
  flex: auto;
  margin-left: #{getSpace("s")};
}

#accessoryListItem .accessoryListItemMenu {
  padding-left: #{getSpace("m")};
}

/* Mobile specific scss */

.accessoryListItemContainerMobile {
  width: 100% !important;
  padding: #{getSpace("xxs")} !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-right: unset !important;
}

#accessoryListItemMobile .accessoryListItemId {
  padding-left: #{getSpace("s")};
  padding-right: #{getSpace("xs")};
}

#accessoryListItemMobile .accessoryType {
  flex: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

#accessoryListItemMobile .accessoryListItemMenu {
  padding-left: #{getSpace("m")};
  height: 56px;
  width: 56px;
  background: #{getColor("elevation", "a03")} !important;
  text-align: center;
  padding: #{getSpace("s")};
  border-radius: #{getSpace("xxs")};
}

#accessoryListItemMobile .accessoryListItemContent {
  flex: auto;
  margin-left: #{getSpace("xs")};
  margin-top: #{getSpace("xs")};
  height: #{getSpace("xl")};
  width: 100% !important;
  flex-basis: 100%; // break to new line
  display: flex;
  align-items: center;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  #accessoryListItemMobile .accessoryListItemContent {
    flex: auto;
    margin-left: unset;
    margin-bottom: #{getSpace("xxs")};
    margin-top: #{getSpace("s")};
  }
}
