@import "../../../theme/variables.scss";

#streamTypes .modalContent {
  --background: #{getColor("elevation", "a00")} !important;
}

.streamTypeContainer {
  display: flex;
  padding-top: getSpace("s") !important;
  padding-left: getSpace("xxs") !important;
  padding-right: getSpace("xxs") !important;
  padding-bottom: getSpace("m") !important;
  flex-wrap: wrap;
  background: #{getColor("elevation", "a00")} !important;
  align-items: center;
}

#streamTypes .streamTypeButton {
  justify-content: space-around;
  align-items: center;
  flex-basis: 45%;
  border-radius: getSpace("xxs");
  height: 124px !important;
  margin: getSpace("xxs") !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
}

#streamTypes .streamTypeDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#streamTypes .streamTypeDivTxt {
  justify-content: center;
  text-align: center;
}

#streamTypes .streamTypeMsg {
  text-align: center;
  padding-left: getSpace("m");
  padding-right: getSpace("m");
}

#streamTypes .streamTypeImage {
  height: getSpace("l");
  width: getSpace("l");
  margin-bottom: getSpace("m");
}

#streamTypes .videoImageFilter {
  filter: brightness(0.7);
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  #streamTypes {
    overflow-y: auto !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles
  ##Screen = B/w 411px to 767px
*/

@media (min-width: 411px) and (max-width: 767px) and (orientation: portrait) {
  .streamTypeContainer {
    padding-left: getSpace("s") !important;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 413px
*/

@media (min-width: 320px) and (max-width: 413px) and (orientation: landscape) {
  #streamTypes {
    overflow-y: auto !important;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 414px) and (max-width: 767px) and (orientation: landscape) {
  #streamTypes {
    overflow-y: auto !important;
  }
}
