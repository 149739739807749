@import "../../../theme/variables.scss";

.twinkleContainer {
  display: flex;
  flex-direction: column;
  padding-top: getSpace("m") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  height: 100%;
}

#twinkleAnimation .animationSpeed {
  margin-top: getSpace("m") !important;
}

#twinkleAnimation .speedSelector {
  display: flex;
  justify-content: space-around;
}
#twinkleAnimation .intensitySelector {
  display: flex;
  justify-content: space-around;
}

#twinkleAnimation .speedButton {
  width: 106px !important;
  height: 56px !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
  border-radius: getSpace("xxs");
}

#twinkleAnimation .activeButton {
  border: 1px solid #{getColor("primary", "base")} !important;
  color: #{getColor("primary", "base")} !important;
}

#twinkleAnimation .activeButtonTxt {
  color: #{getColor("primary", "base")} !important;
}

#twinkleAnimation .speed {
  text-transform: capitalize;
  color: #{getColor("whiteColor", "a96")} !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
}

#twinkleAnimation .intensityButton {
  width: 106px !important;
  height: 56px !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
  border-radius: getSpace("xxs");
}

#twinkleAnimation .intensity {
  text-transform: capitalize;
  color: #{getColor("whiteColor", "a96")} !important;
  background: #{getColor("elevation", "a01")} !important;
  cursor: pointer;
}

#twinkleAnimation #colorAnimation .colorHeading {
  margin-bottom: getSpace("xxs") !important;
}

#twinkleAnimation #colorAnimation .colorPicker {
  margin-bottom: getSpace("xs") !important;
  height: 56px !important;
}

#twinkleAnimation #colorAnimation .colorTxt {
  padding-left: 4%;
  padding-right: 4%;
}

#animationSpeed .speedHeading {
  margin-top: getSpace("xs") !important;
  margin-bottom: getSpace("xxs") !important;
}
#animationIntensity .intensityHeading {
  margin-top: getSpace("xs") !important;
  margin-bottom: getSpace("xxs") !important;
}
