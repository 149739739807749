@import "../../../theme/variables.scss";

.streamContainer {
  display: flex;
  flex-direction: column;
  padding-top: getSpace("l") !important;
  padding-left: getSpace("s") !important;
  padding-right: getSpace("s") !important;
  height: 100%;
}

.streamSelectorBox {
  background: #{getColor("elevation", "a01")};
  height: 232px;
  width: 343px;
  border: 1px solid getColor("utility", "separator") !important;
  border-radius: #{getSpace("xxs")};
  align-self: center;
}

.addStreamContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 69px;
}

.viewStreamContainer {
  align-items: center;
}

#videoStreamMedia .mediaIcon {
  height: getSpace("m");
  width: getSpace("m");
}

#videoStreamMedia .addMark {
  height: getSpace("xxl");
  width: getSpace("xxl");
  padding: getSpace("xs");
  border: 1px solid getColor("utility", "separator") !important;
  border-radius: #{getSpace("xxs")};
  background: #{getColor("elevation", "a01")};
  text-align: center;
}

#videoStreamMedia .streamIconContainer {
  top: 69px;
  left: 140px;
  height: getSpace("xxl");
  width: getSpace("xxl");
  padding: getSpace("xs");
  text-align: center;
  border: 1px solid getColor("utility", "separator") !important;
  position: relative;
  border-radius: #{getSpace("xxs")};
  background: #{getColor("elevation", "a01")};
}

#videoStreamMedia .streamImage {
  height: getSpace("l");
  width: getSpace("l");
}

#videoStreamMedia .streamMetaInfo {
  border-top: 1px solid getColor("utility", "separator") !important;
  position: relative;
  top: 100px;
  padding-left: 20px;
}

.createStream {
  border-color: transparent !important;
  --box-shadow: none !important;
  --ripple-color: transparent !important;
}

.addStreamMarkPosition {
  height: auto;
  width: auto;
  margin-left: 4px;
}

.addStreamIcon {
  position: relative;
  color: #{getColor("whiteColor", "a64")} !important;
  height: getSpace("m") !important;
  width: getSpace("m") !important;
}

.addStreamText {
  width: max-content;
  position: relative;
  margin-top: getSpace("xs");
}

.streamMetaName {
  margin-top: 12px;
}
.streamName {
  color: #{getColor("whiteColor", "a96")} !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.addVideoText {
  text-transform: uppercase;
  color: #{getColor("whiteColor", "a64")} !important;
}

.streamInfo {
  color: #{getColor("whiteColor", "a96")} !important;
  border-top: 1px solid getColor("utility", "separator") !important;
  flex: auto;
  margin-left: 20px;
}

.closeButtonContainer {
  position: relative;
}

.closeStreamContainer {
  height: 44px;
  width: 44px;
  background: #{getColor("glass", "thick")} !important;
  padding: 10px;
}

#videoStreamMedia .removeStreamIcon {
  height: getSpace("m");
  width: getSpace("m");
}

#videoStreamMedia .accessoryListItemRemoveStreamButton {
  --background: #{getColor("elevation", "a03")} !important;
  --border: 0px;
  --color: #{getColor("whiteColor", "a96")} !important;
  --background-hover: #{getColor("elevation", "a03")} !important;
  --padding-start: 0px;
  --padding-end: 0px;
  margin-top: getSpace("s");
  margin-right: getSpace("s");
  margin-bottom: getSpace("s");
}

#videoStreamMedia {
  ion-fab-button {
    --background: #{getColor("glass", "thick")} !important;
    --background-hover: #{getColor("glass", "thick")} !important;
    --background-activated: #{getColor("glass", "thick")} !important;
    --background-focused: #{getColor("glass", "thick")} !important;
    --close-icon-font-size: #{getSpace("m")} !important;
    height: 44px;
    width: 44px;
    --border-radius: #{getSpace("xxs")} !important;
  }
}

.mediaModal {
  .modal-wrapper {
    display: block;
    width: 540px !important;
    height: 600px !important;
    border-radius: getSpace("xxs") !important;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1023px
*/

@media (min-width: 768px) and (max-width: 1023px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
 
  #videoStreamMedia .streamSelectorBox {
    width: 98%;
  }
  
}

/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
  #videoStreamMedia .streamSelectorBox {
    width: 98%;
  }

  #videoStreamMedia .streamImageBox {
    width: 100%;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Landscape)
  ##Screen = B/w 320px to 480px
*/

@media (min-width: 320px) and (max-width: 480px) and (orientation: landscape) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
  #videoStreamMedia .streamIconContainer {
    img {
      width: 100%;
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = 320px
*/

@media (max-width: 320px) {
  .mediaModal {
    .modal-wrapper {
      height: 100% !important;
    }
  }
}
