@import "../../theme/variables.scss";

#login-page ion-row {
  align-items: center;
  justify-content: center;
}


.content {
  height: 100vh;
  display: flex;
  align-items: center;
}

.greetMsg {
  text-align: center;
}

.passwordRow {
  margin-top: getSpace('xl');
}

.errorRowNewPwd {
  margin-top: getSpace('s');
}

.subText {
  text-align: center;
}

.submitRow {
  margin-top: 56px;
}