@import "../../../theme/variables.scss";

#createEditGroupedListItem .accessoryItem {
  --background: #{getColor("elevation", "a02")} !important;
  --color-hover: transparent !important;
  --background-hover: transparent !important;
  --background-opacity: 1 !important;
  --color-focused: #{getColor("elevation", "a02")} !important;
  --color-activated: #{getColor("elevation", "a02")} !important;
  height: 100% !important;
  width: 100% !important;
  display: flex;
  align-items: stretch;
  align-content: center;
  padding: 0px !important;
}

#createEditGroupedListItem .accessoryItemContainer {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
}


#createEditGroupedListItem .accessoryDiv {
  display: flex;
  flex: auto;
}

#createEditGroupedListItem .accessoryPosition {
  margin-left: getSpace("l");
  width: 30px;
}

#createEditGroupedListItem .accessoryCheckbox {
  --background: #{getColor("elevation", "a02")} !important;
  --background-checked: #{getColor("elevation", "a02")} !important;
  --border-color: transparent !important;
  --border-width: 0px !important;
  --border-color-checked: #{getColor("elevation", "a02")} !important;
  --checkmark-color: #{getColor("primary", "base")} !important;
}

#createEditGroupedListItem .no-ripple {
  --ripple-color: transparent;
  --background-activated: transparent;
}

#createEditGroupedListItem .modalContent {
  --background: #{getColor("elevation", "a01")} !important;
}

#createEditGroupedListItem .blinkButton {
  color: #{getColor("whiteColor", "a96")} !important;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  border: 1px solid #{getColor("utility", "separator")} !important;
  height: 44px !important;
  width: 90px !important;
  box-sizing: border-box;
  border-radius: getSpace("xxs");
}

#createEditGroupedListItem .dimmerIcon {
  margin-right: 3%;
}

