@import "../../theme/variables.scss";

.slidecontainer {
  width: 100%;
}

/* The slider itself */
.slider {
  display: inline-block;
  vertical-align: middle;

  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: #{getSpace("xxxxs")};
  /* Specified height */
  background: #{getColor("elevation", "a00")};
  /* Grey background */
  outline: none;
  /* Remove outline */
  /* opacity: 0.7; */
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  border: 2px solid #{getColor("darkGreyColor", "base")};
  height: 29px !important;
  width: 29px !important;
  border-radius: 50% !important;
  background: #{getColor("elevation", "a02")};
  backdrop-filter: blur(250px);
  /* background: url("../../assets/images/wb_sunny-white.svg") no-repeat center
    darken($color: getColor("elevation", "a00"), $amount: 0); */
  cursor: pointer;
  -webkit-appearance: none;
}

.slider::-moz-range-thumb {
  border: 1px solid #{getColor("darkGreyColor", "base")};
  height: 29px !important;
  width: 29px !important;
  border-radius: 50% !important;
  background: #{getColor("elevation", "a02")};
  backdrop-filter: blur(250px);
  /* background: url("../../assets/images/wb_sunny-white.svg") no-repeat center
    darken($color: getColor("elevation", "a00"), $amount: 0); */
  cursor: pointer;
  -webkit-appearance: none;
}

.slider::-ms-thumb {
  border: 1px solid #{getColor("darkGreyColor", "base")};
  height: 29px !important;
  width: 29px !important;
  border-radius: 50% !important;
  background: #{getColor("elevation", "a02")};
  backdrop-filter: blur(250px);
  /* background: url("../../assets/images/wb_sunny-white.svg") no-repeat center
    darken($color: getColor("elevation", "a00"), $amount: 0); */
  cursor: pointer;
  -webkit-appearance: none;
}
