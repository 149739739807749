@import "../../theme/variables.scss";

.modalFullScreen {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
