/* Input and Input Label Global Styling */

.md, .ios {
    ion-input {
      input::placeholder {
        color: getColor('whiteColor', 'a32') !important;
      }
      input:focus::placeholder {
        color: getColor('whiteColor', 'a64') !important;
      }
      @include font('body1');
      color: getColor('whiteColor', 'a96') !important;
    }
  
    ion-label {
      @include font('body2');
      margin-bottom: getSpace('xxs') !important;
      color: getColor('whiteColor', 'a64') !important;
    }
  
    ion-item.item-has-focus > ion-label{
      color: getColor('whiteColor', 'a64') !important;
    }
    
    ion-item {
      --highlight-color-focused: none !important;
    }
  }